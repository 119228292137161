<template>
  <v-card tile class="pa-4">
    <!-- <v-card-title class="text-h5 primary mb-2">
      <span class="white--text">
        Notificación
      </span>
    </v-card-title> -->

    <v-card-text class="text-center">
      <v-row class="d-flex">
        <v-col cols="12" md="12">
          <div style="border-bottom: solid 1px #ef4370">
            <span class="d-flex flex-row justify-sm-space-between align-center">
              <h2>Paradas</h2>
              <v-icon>{{ icons.mdiMapMarkerPath }}</v-icon>
            </span>
            <br />
          </div>
          <v-list>
            <v-list-item-content
              v-for="(stop, index) in item.routes"
              :key="stop.index"
            >
              <span v-if="index === 0" class="mb-6">
                <v-list-item-title class="d-flex flex-row align-center">
                  <v-img
                    :src="require(`@/views/ParcelMultistop/assets/Stop${index}.png`)"
                    max-width="42"
                  ></v-img>
                  {{ stop.source_address.split(",").splice(0, 2).join(",") }}
                </v-list-item-title>
              </span>
              <span :style="index % 2 === 0 && 'background-color: #FAFAFA;'">
              <v-list-item-title class="d-flex flex-row align-center">
                <v-img
                  :src="
                    require(`@/views/ParcelMultistop/assets/Stop${index + 1}.png`)
                  "
                  max-width="42"
                ></v-img>
                {{ stop.destination_address.split(",").splice(0, 2).join(",") }}
              </v-list-item-title>
              <v-list-item-subtitle class="d-flex flex-row justify-start ml-12">
                <span>
                  Total parada:
                  {{ stop.estimated_fare | currency }}
                </span>
                <span class="ml-1"> | </span>
                <span class="ml-1">
                  Tiempo estimado:
                  {{ Math.ceil(stop.time) }}m
                </span>
                <span class="ml-1"> | </span>
                <span class="ml-1">
                  Distancia estimada:
                  {{ stop.distance }}Km
                </span>
              </v-list-item-subtitle>
              <br />
              <v-list-item three-line>
                <v-row>
                  <v-col cols="12" md="5">
                    <v-list-item-content
                      :style="!$vuetify.breakpoint.mdAndUp && 'border-right: solid 1px #3bd4ae'"
                      class="d-flex flex-column align-center"
                    >
                      <v-list-item-title>
                        Detalles de quién recibe
                      </v-list-item-title>
                      <!-- Nombre y apellido -->
                      <v-list-item-subtitle>
                        {{
                          `${stop.shipment.responsible_first_name} ${stop.shipment.responsible_last_name}`
                        }}
                      </v-list-item-subtitle>
                      <!-- telefono -->
                      <v-list-item-subtitle>
                        <a :href="`tel:+58${stop.shipment.responsible_phone}`">
                          {{ stop.shipment.responsible_phone }}</a
                        >
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-col>
                  <v-col cols="12" md="7">
                    <v-list-item-content class="pl-4 d-flex flex-column align-center" >
                      <v-list-item-title>
                        Detalles del paquete
                      </v-list-item-title>
                      <!-- detalles -->
                      <v-list-item-subtitle
                        class="text-justify"
                      >
                        {{ stop.shipment.package_description }}
                      </v-list-item-subtitle>
                    </v-list-item-content>
                  </v-col>
                </v-row>
              </v-list-item>

              </span>
            </v-list-item-content>
          </v-list>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="12" class="d-flex flex-row justify-center">
          <v-btn color="primary" max-width="400" @click="$emit('submit', null)">
            Aceptar
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiMapMarkerPath } from "@mdi/js";
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiMapMarkerPath,
      },
    };
  },
};
</script>

<style lang="sass" scoped>
.vuedl-layout__closeBtn
    // color: white
    // opacity: 1
    color: rgba(58, 53, 65, 0.54)
    // margin-top: 8px

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0
    height: 200vh
</style>
