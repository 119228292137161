<template>
  <v-card tile class="pa-4">
    <!-- <v-card-title class="text-h5 primary mb-2">
      <span class="white--text">
        Notificación
      </span>
    </v-card-title> -->
    <!-- <pre>{{ item }}</pre> -->

    <v-card-text class="text-center">
      <!-- <v-row class="d-flex">
        <v-col cols="12" md="12">
          <div style="border-bottom: solid 1px #ef4370">
            <span class="d-flex flex-row justify-sm-space-between align-center">
              <h2>Detalles de envío</h2>
              <v-icon>{{ icons.mdiPackageVariantClosed }}</v-icon>
            </span>
            <br />
          </div>
        </v-col>
      </v-row> -->
      <template v-if="item.shipment">
        <v-row class="">
          <v-col cols="12" class="d-flex justify-space-between">
            <h2 class="ml-3">
              {{ ES ? "Detalles del envío" : "Parcel details" }}
            </h2>
            <a
              :href="`https://api.whatsapp.com/send?phone=+58${item.shipment.responsible_phone}&text=Hola, %20te%20estoy%20enviando%20%20por%20Ridery`"
              target="_blank"
            >
              <v-icon>{{ icons.mdiWhatsapp }}</v-icon>
            </a>
          </v-col>
        </v-row>
        <v-row class="d-flex flex-row align-stretch">
          <!-- origen -->
          <template>
            <v-col
              cols="12"
              md="4"
              class="px-10"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'border-right: 1px solid #898989'
                  : 'border-bottom: 1px solid #898989'
              "
            >
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex flex-row align-start justify-center"
                >
                  <v-img
                    :src="require('../../../assets/IconOriginMapMap.png')"
                    contain
                    max-width="40"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" class="d-flex flex-column align-start">
                  <!-- origen -->
                  <span class="text-justify">
                    <span class="font-weight-bold success--text">{{
                      ES ? "Origen:" : "Origin:"
                    }}</span>
                    <span>{{ item.source_address }}</span>
                  </span>
                  <span class="font-weight-bold success--text">{{
                    ES ? "Persona que envía:" : "Person that sends:"
                  }}</span>
                  <span>
                    {{
                      item.shipment.is_user_sender
                        ? item.user_name
                        : item.shipment.responsible_full_name
                    }}
                  </span>
                  <span>
                    <span class="font-weight-bold success--text">{{
                      ES ? "Teléfono:" : "Telephone:"
                    }}</span>
                    <!-- <span>
                      {{
                        item.shipment.is_user_sender
                          ? item.user_phone.slice(3)
                          : item.shipment.responsible_phone
                      }}
                    </span> -->
                    <a
                      :href="`tel:${item.user_phone}`"
                      v-if="item.shipment.is_user_sender"
                    >
                      {{ item.user_phone && item.user_phone.slice(3) }}
                    </a>
                    <a
                      :href="`tel:+58${item.shipment.responsible_phone}`"
                      v-else
                    >
                      {{ item.shipment.responsible_phone }}
                    </a>
                  </span>
                  <span class="text-justify">
                    <span class="font-weight-bold success--text">{{
                      ES
                        ? "Instrucciones en el origen:"
                        : "Instructions at the origin:"
                    }}</span>
                    <span class="text-justify" style="white-space: pre-wrap">
                      {{ item.shipment.source_instructions }}
                    </span>
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </template>
          <!-- destino -->
          <template>
            <v-col
              cols="12"
              md="4"
              class="px-10"
              :style="
                $vuetify.breakpoint.mdAndUp
                  ? 'border-right: 1px solid #898989'
                  : 'border-bottom: 1px solid #898989'
              "
            >
              <v-row>
                <v-col
                  cols="12"
                  class="d-flex flex-row align-start justify-center"
                >
                  <v-img
                    :src="require('../../../assets/IconDestinationMapMap.png')"
                    contain
                    max-width="40"
                  >
                    <template v-slot:placeholder>
                      <v-row
                        class="fill-height ma-0"
                        align="center"
                        justify="center"
                      >
                        <v-progress-circular
                          indeterminate
                          color="grey lighten-5"
                        ></v-progress-circular>
                      </v-row>
                    </template>
                  </v-img>
                </v-col>
                <v-col cols="12" class="d-flex flex-column align-start">
                  <!-- origen -->
                  <span class="text-justify">
                    <span class="font-weight-bold error--text">{{
                      ES ? "Destino:" : "Destiny:"
                    }}</span>
                    <span>{{ item.destination_address }}</span>
                  </span>
                  <span class="font-weight-bold error--text">{{
                    ES ? "Persona que recibe:" : "Person that receives:"
                  }}</span>
                  <span>
                    {{
                      !item.shipment.is_user_sender
                        ? item.user_name
                        : item.shipment.responsible_full_name
                    }}
                  </span>
                  <span>
                    <span class="font-weight-bold error--text">{{
                      ES ? "Teléfono:" : "Telephone:"
                    }}</span>
                    <a
                      :href="`tel:${item.user_phone}`"
                      v-if="!item.shipment.is_user_sender"
                    >
                      {{ item.user_phone && item.user_phone.slice(3) }}
                    </a>
                    <a
                      :href="`tel:+58${item.shipment.responsible_phone}`"
                      v-else
                    >
                      {{ item.shipment.responsible_phone }}
                    </a>
                  </span>
                  <span class="text-justify">
                    <span class="font-weight-bold error--text">{{
                      ES
                        ? "Instrucciones en el destino:"
                        : "Instructions at the destiny:"
                    }}</span>
                    <span class="text-justify" style="white-space: pre-wrap">
                      {{ item.shipment.destination_instructions }}
                    </span>
                  </span>
                </v-col>
              </v-row>
            </v-col>
          </template>
          <!-- destino -->
          <template>
            <v-col
              cols="12"
              md="4"
              class="d-flex flex-column justify-center px-10"
            >
              <span class="text--primary fontUrbanist font-weight-bold">
                {{ ES ? "Foto del paquete" : "Package photo" }}
              </span>
              <span class="d-flex justify-center mt-2">
                <v-dialog
                  v-if="item.shipment.user_picture"
                  v-model="dialogPackagePhoto"
                  fullscreen
                  transition="dialog-bottom-transition"
                  scrollable
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-fade-transition mode="out-in">
                      <v-img
                        :src="item.shipment.user_picture"
                        width="70"
                        height="70"
                        v-bind="attrs"
                        v-on="on"
                        class="hover-image rounded-lg"
                        contain
                      >
                        <template v-slot:placeholder>
                          <v-row
                            class="fill-height ma-0"
                            align="center"
                            justify="center"
                          >
                            <v-progress-circular
                              indeterminate
                              color="primary"
                            ></v-progress-circular>
                          </v-row>
                        </template>
                      </v-img>
                    </v-fade-transition>
                  </template>
                  <v-card class="testcard" style="overflow-y: auto">
                    <v-row dense>
                      <v-col cols="12" class="">
                        <v-toolbar
                          color="primary"
                          class="d-flex justify-space-between"
                          tile
                        >
                          <span
                            class="d-flex flex-row justify-space-between"
                            style="width: 98vw"
                          >
                            <v-toolbar-title class="color-white-text">{{
                              ES ? "Foto del paquete" : "Package photo"
                            }}</v-toolbar-title>
                            <v-btn
                              icon
                              dark
                              @click="dialogPackagePhoto = false"
                            >
                              <v-icon>{{ icons.mdiClose }}</v-icon>
                            </v-btn>
                          </span>
                        </v-toolbar>
                      </v-col>
                    </v-row>
                    <v-row dense>
                      <v-col
                        cols="12"
                        class="d-flex align-start justify-center pa-12"
                      >
                        <v-img
                          :src="item.shipment.user_picture"
                          class="modal-image"
                          contain
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-col>
                    </v-row>
                  </v-card>
                </v-dialog>
              </span>
              <span class="text-center mt-2">
                <span class="font-weight-bold primary--text text-caption">{{
                  ES ? "Descripción del paquete:" : "Package description:"
                }}</span>
                <br />
                <span class="text-justify" style="white-space: pre-wrap">
                  {{ item.shipment.package_description }}
                </span>
              </span>
              <template v-if="item.shipment.provider_picture">
                <v-divider color="#898989"></v-divider>
                <span class="text--primary fontUrbanist font-weight-bold mt-2">
                  {{
                    ES
                      ? "Foto de la encomienda (conductor)"
                      : "Package photo (driver)"
                  }}
                </span>
                <span class="d-flex justify-center mt-2">
                  <v-dialog
                    v-if="item.shipment.provider_picture"
                    v-model="dialogPackagePhotoDriver"
                    fullscreen
                    transition="dialog-bottom-transition"
                    scrollable
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-fade-transition mode="out-in">
                        <v-img
                          :src="item.shipment.provider_picture"
                          width="70"
                          height="70"
                          v-bind="attrs"
                          v-on="on"
                          class="hover-image rounded-lg"
                          contain
                        >
                          <template v-slot:placeholder>
                            <v-row
                              class="fill-height ma-0"
                              align="center"
                              justify="center"
                            >
                              <v-progress-circular
                                indeterminate
                                color="primary"
                              ></v-progress-circular>
                            </v-row>
                          </template>
                        </v-img>
                      </v-fade-transition>
                    </template>
                    <v-card class="testcard" style="overflow-y: auto">
                      <v-row dense>
                        <v-col cols="12" class="">
                          <v-toolbar
                            color="primary"
                            class="d-flex justify-space-between"
                            tile
                          >
                            <span
                              class="d-flex flex-row justify-space-between"
                              style="width: 98vw"
                            >
                              <v-toolbar-title class="color-white-text">
                                {{
                                  ES ? "Foto del paquete" : "Package photo"
                                }}</v-toolbar-title
                              >
                              <v-btn
                                icon
                                dark
                                @click="dialogPackagePhotoDriver = false"
                              >
                                <v-icon>{{ icons.mdiClose }}</v-icon>
                              </v-btn>
                            </span>
                          </v-toolbar>
                        </v-col>
                      </v-row>
                      <v-row dense>
                        <v-col
                          cols="12"
                          class="d-flex align-start justify-center pa-12"
                        >
                          <v-img
                            :src="item.shipment.provider_picture"
                            class="modal-image"
                            contain
                          >
                            <template v-slot:placeholder>
                              <v-row
                                class="fill-height ma-0"
                                align="center"
                                justify="center"
                              >
                                <v-progress-circular
                                  indeterminate
                                  color="primary"
                                ></v-progress-circular>
                              </v-row>
                            </template>
                          </v-img>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-dialog>
                </span>
              </template>
            </v-col>
          </template>
        </v-row>
      </template>
      <v-row>
        <v-col cols="12" class="d-flex flex-row justify-center">
          <v-btn color="primary" max-width="400" @click="$emit('submit', null)">
            {{ ES ? "Aceptar" : "Accept" }}
          </v-btn>
        </v-col>
      </v-row>
    </v-card-text>
  </v-card>
</template>

<script>
import { mdiPackageVariantClosed, mdiWhatsapp, mdiClose } from "@mdi/js";
export default {
  props: {
    item: {
      type: Object,
    },
  },
  data() {
    return {
      icons: {
        mdiPackageVariantClosed,
        mdiWhatsapp,
        mdiClose,
      },
      dialogPackagePhoto: false,
      dialogPackagePhotoDriver: false,
    };
  },
  computed: {
    ES() {
      return this.$vuetify.lang.current === "es";
    },
  },
  created() {
    console.log(this.item);
  },
};
</script>

<style lang="scss" scoped>
.vuedl-layout__closeBtn {
  // color: white
  // opacity: 1
  color: rgba(58, 53, 65, 0.54);
  // margin-top: 8px
}

.v-dialog.vuedl-layout.v-dialog--active.v-dialog--fullscreen
  .v-card.v-sheet.theme--light.rounded-0 {
  min-height: 100vh;
}

.modal-image {
  max-width: 70vw;
  max-height: 70vw;
  // max-height: 500px;
  // max-width: 800px;
}

.hover-image:hover {
  cursor: pointer;
}
</style>
